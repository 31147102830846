import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: {
        en: {
            "Hello": "Who searches will always find!",
            //menu
            "Home": "Home",
            "About": "About",
            "Contact": "Contact",
            "Login": "Login",
            "Logout": "Logout",
            "Register": "Register",
            "Account": "Account",
            "Adverts": "My Ads",
            "Favourites": "Favourites",
            "Messages": "Messages",
            "My Profile": "My Profile",
            "Bills": "Bills",
            "History": "History",
            "Payment Methods": "Payment Methods",
            "Notifications": "Notifications",
            "Password": "Password",
            "Settings": "Settings",
            "Dashboard": "Dashboard",
            //footer
            "About us": "About us",
            "Contact us": "Contact us",
            "Terms and conditions": "Terms and conditions",
            //interface elements
            "Search": "Search",
            "Location": "Location",
            "isType": "Type",
            "Categories": "Categories",
            "postAd": "Post Ad",
            "Generate Description": "Generate Description",
            "Show mobile": "Show your mobile number to the buyer?",
            "Save Draft": "Save Draft",
            "Publish": "Publish",
            "Previous": "Previous",
            "Next": "Next",
            "Images": "Images",
            "Drop images": "Drop your images here or click to upload",
            "Continue to next step": "Continue to next step",
            //sections
            "Details": "Details",
            "Select category": "Select category",
            "Select subcategory": "Select subcategory",
            "Location": "Location",
            "Price": "Price",
            //forms
            "Options": "Options",
            "Category": "Category",
            "Subcategory": "Subcategory",
            "City": "City",
            "Address": "Address",
            "Currency": "Currency",
            "Select": "Select",
            "Select Model": "Select Model",
            "Model": "Model",
            "Title": "Title",
            "Description": "Description",
            //messages
            "Please enter your email": "Please enter your email",
            "Please enter your password": "Please enter your password",
            "Please enter your name": "Please enter your name",
            "Please enter your phone number": "Please enter your phone number",
            "Please enter your city": "Please enter your city",
            "Please enter your address": "Please enter your address",
            "Please enter your password confirmation": "Please enter your password confirmation",
            "Please select your category": "Please select your category",
            "Please select your subcategory": "Please select your subcategory",
            "Successfull Advert Post": "You have successfully created your ad. You can now publish it or save it as a draft.",
            "This field is required": "This field is required",
            "Please enter a valid email": "Please enter a valid email",
            "Please enter a Password": "Please enter a Password",
            "You did not pass the human test.": "You did not pass the human test.",
            //titles
            "Information": "Information",
            "Images": "Images",
            "Finish": "Images",
            //general
            "Select a category and subcategory": "Select a category and subcategory",
            "You can use the AI generator to create a description for your product. To get the best result please fill in the product details.": "You can use the AI generator to create a description for your product. To get the best result please fill in the product details.",
            "You are logged in as": "You are logged in as",
            
        },
        ro: {
            "Hello": "Cine cauta, gaseste!",
            //menu
            "Home": "Acasa",
            "About": "Despre",
            "Contact": "Contact",
            "Login": "Autentificare",
            "Logout": "Iesire din cont",
            "Register": "Inregistrare",
            "Account": "Contul meu",
            "Adverts": "Anunturile mele",
            "Favourites": "Favorite",
            "Messages": "Mesage",
            "My Profile": "Profilul meu",
            "Bills": "Facturi",
            "History": "Istoric",
            "Payment Methods": "Metode de plata",
            "Notifications": "Notificari",
            "Password": "Parola",
            "Settings": "Setari",
            "Dashboard": "Panou de control",
            //footer
            "About us": "Despre noi",
            "Contact us": "Contacteaza-ne",
            "Terms and conditions": "Termeni si conditii",
            //interface elements
            "Search": "Cauta",
            "Location": "Locatie",
            "isType": "Tip",
            "Categories": "Categorii",
            "postAd": "Posteaza Anunt",
            "Generate Description": "Genereaza Descriere",
            "Show mobile": "Arata numarul de telefon catre cumparator?",
            "Save Draft": "Salveaza schița",
            "Publish": "Publica",
            "Previous": "Inapoi",
            "Next": "Inainte",
            "Images": "Imagini",
            "Drop images": "Trage imaginile aici sau apasa pentru a le incarca",
            "Continue to next step": "Continua la pasul urmator",
            //sections
            "Details": "Detalii",
            "Select category": "Selecteaza categoria",
            "Select subcategory": "Selecteaza subcategoria",
            "Location": "Locatie",
            "Price": "Pret",
            //forms
            "Options": "Optiuni",
            "Category": "Categorie",
            "Subcategory": "Subcategorie",
            "City": "Oras",
            "Address": "Adresa",
            "Currency": "Moneda",
            "Select": "Selecteaza",
            "Select Model": "Selecteaza Model",
            "Model": "Model",
            "Title": "Titlu",
            "Description": "Descriere",
            //messages
            "Please enter your email": "Introduceti adresa de email",
            "Please enter your password": "Introduceti parola",
            "Please enter your name": "Introduceti numele",
            "Please enter your phone number": "Introduceti numarul de telefon",
            "Please enter your city": "Introduceti orasul",
            "Please enter your address": "Introduceti adresa",
            "Please enter your password confirmation": "Introduceti confirmarea parolei",
            "Please select your category": "Selectati categoria",
            "Please select your subcategory": "Selectati subcategoria",
            "Successfull Advert Post": "Ati creat cu succes anuntul. Acum il puteti publica sau il puteti salva ca si ciorna.",
            "This field is required": "Acest camp este obligatoriu",
            "Please enter a valid email": "Introduceti o adresa de email valida",
            "Please enter a Password": "Introduceti o parola",
            "You did not pass the human test.": "Nu ati trecut testul de umanitate.",
            //titles
            "Information": "Informatii",
            "Images": "Imagini",
            "Finish": "Finalizare",
            //general
            "Select a category and subcategory": "Selectati o categorie si o subcategorie",
            "You can use the AI generator to create a description for your product. To get the best result please fill in the product details.": "Puteti folosi generatorul AI pentru a crea o descriere pentru produsul dumneavoastra. Pentru a obtine cel mai bun rezultat va rugam sa completati detaliile produsului.",
            "You are logged in as": "Sunteti autentificat ca",

        },
        ru: {
            "Hello": "Кто ищет, тот всегда найдет!",
            //menu
            "Home": "Главная",
            "About": "О нас",
            "Contact": "Контакты",
            "Login": "Вход",
            "Logout": "Выход",
            "Register": "Регистрация",
            "Account": "Мой аккаунт",
            "Adverts": "Мои объявления",
            "Favourites": "Избранное",
            "Messages": "Сообщения",
            "My Profile": "Мой профиль",
            "Bills": "Счета",
            "History": "История",
            "Payment Methods": "Способы оплаты",
            "Notifications": "Уведомления",
            "Password": "Пароль",
            "Settings": "Настройки",
            "Dashboard": "Панель управления",
            //footer
            "About us": "О нас",
            "Contact us": "Связаться с нами",
            "Terms and conditions": "Условия и положения",
            //interface elements
            "Search": "Поиск",
            "Location": "Местоположение",
            "isType": "Тип",
            "Categories": "Категории",
            "postAd": "Разместить Объявление",
            "Generate Description": "Сгенерировать Описание",
            "Show mobile": "Показать ваш номер телефона покупателю?",
            "Save Draft": "Сохранить черновик",
            "Publish": "Опубликовать",
            "Previous": "Назад",
            "Next": "Далее",
            "Images": "Изображения",
            "Drop images": "Перетащите изображения сюда или нажмите для загрузки",
            "Continue to next step": "Продолжить к следующему шагу",
            //sections
            "Details": "Детали",
            "Select category": "Выберите категорию",
            "Select subcategory": "Выберите подкатегорию",
            "Location": "Местоположение",
            "Price": "Цена",
            //forms
            "Options": "Опции",
            "Category": "Категория",
            "Subcategory": "Подкатегория",
            "City": "Город",
            "Address": "Адрес",
            "Currency": "Валюта",
            "Select": "Выбрать",
            "Select Model": "Выбрать Модель",
            "Model": "Модель",
            "Title": "Заголовок",
            "Description": "Описание",
            //messages
            "Please enter your email": "Пожалуйста, введите ваш адрес электронной почты",
            "Please enter your password": "Пожалуйста, введите ваш пароль",
            "Please enter your name": "Пожалуйста, введите ваше имя",
            "Please enter your phone number": "Пожалуйста, введите ваш номер телефона",
            "Please enter your city": "Пожалуйста, введите ваш город",
            "Please enter your address": "Пожалуйста, введите ваш адрес",
            "Please enter your password confirmation": "Пожалуйста, введите подтверждение пароля",
            "Please select your category": "Пожалуйста, выберите свою категорию",
            "Please select your subcategory": "Пожалуйста, выберите свою подкатегорию",
            "Successfull Advert Post": "Вы успешно создали объявление. Теперь вы можете опубликовать его или сохранить как черновик.",
            "This field is required": "Это поле обязательно для заполнения",
            "Please enter a valid email": "Пожалуйста, введите действительный адрес электронной почты",
            "Please enter a Password": "Пожалуйста, введите пароль",
            "You did not pass the human test.": "Вы не прошли тест человека.",
            //titles
            "Information":"Информация",
            "Images":"Изображения",
            "Finish":"Завершить",
            "Adverts": "Объявления",
            //general
            "Select a category and subcategory": "Выберите категорию и подкатегорию",
            "You can use the AI generator to create a description for your product. To get the best result please fill in the product details.": "Вы можете использовать генератор AI для создания описания для вашего продукта. Чтобы получить лучший результат, пожалуйста, заполните детали продукта.",
            "You are logged in as": "Вы вошли как",
        },
    },
});