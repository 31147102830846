import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";
import VueMeta from "vue-meta";
import {i18n} from "./plugins/i18n";

Vue.config.productionTip = false;
//axios default url
axios.defaults.baseURL = "https://salex.md/api";

Vue.prototype.$env = {
  siteTitle: "Salex",
  siteUrl: "https://salex.md/",
  shareImg: "/img/share.jpg",
  api: "https://salex.md/api",
  imagesURL: "https://salex-images-live.s3.eu-central-1.amazonaws.com/",
};

Vue.use(Buefy, {
  defaultIconPack: "syntho",
});
Vue.use(VueAxios, axios);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");