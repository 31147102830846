<template>
  <!-- upload -->
  <div class="box mx-2">

      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true"></b-loading>
 
        <!-- images -->
        <div v-if="itemExists && !isLoading">
          <div v-if="maxImages > 0">
          <b-field label="Add Images">
          <b-upload
            v-model="imagesData"
            multiple
            drag-drop
            accept="image/png, image/jpeg"
            ref="images"
            @input="imagesCount()"
          >
            <section class="section">
              <div class="content has-text-centered">
                <span class="is-size-1">
                  <b-icon icon="arrow-up-from-square"> </b-icon>
                </span>
                <p>Click here to select images and upload</p>
              </div>
            </section>
          </b-upload>
        </b-field>
        </div>
        <div class="uploader-message-wrapper" v-else>
          <div class="uploader-message">
            <b-icon icon="face-smile-wink"></b-icon>
            <p>You have reached the maximum number of images allowed</p>
          </div>
        </div>


        <div class="my-5" v-if="hasQueue">
          <div class="has-text-centered my-5">
        <b-button
          type="is-success is-justified-center"
          @click="uploadImages()"
          :disabled="!hasQueue"
          icon-left="arrow-up-from-square"
          size="is-medium"
          >Upload Selected Images</b-button
        >
      </div>
        <div class="has-text-centered is-size-4"><strong>Upload Queue</strong></div>
        <div class="queue-grid  mb-3">
          <div
            v-for="(item, index) in imagesData"
            :key="index"
            class="grid-item"
          >
            <div class="imageitem">
              <span class="tag is-info image-tag">
                Remove
                <button
                  class="delete is-small"
                  type="button"
                  @click="removeImageFile(index)"
                ></button>
              </span>
              <img class="image" :src="showFile(item)" />
            </div>
          </div>
        </div>
    </div>

        <!-- images -->
        <div class="my-5" v-if="hasImages">
          <div class="has-text-centered is-size-4"><strong>Advert Images</strong></div>
          <div class="queue-grid mb-1">
            <div
              v-for="(item, index) in imageFiles"
              :key="index"
              class="grid-item"
            >
              <div class="imageitem">
                <span class="tag is-danger image-tag">
                  Delete
                  <button
                    class="delete is-small"
                    type="button"
                    @click="warningAdvertFile(item.image, index)"
                  ></button>
                </span>
                <img class="image" :src="$env.imagesURL +'img/'+ item.image" />
              </div>
            </div>
          </div>
        </div>        <!-- images end -->   
        </div>
        <!-- images end -->
        <div v-if="!itemExists && !isLoading">
          <div class="uploader-message-wrapper">
            <div class="uploader-message">
              <b-icon icon="triangle-exclamation"></b-icon>
              <p>We could not find your advert.</p>
            </div>
          </div>
        </div>

        

  </div>
  <!-- end upload -->
</template>

<script>
import { mapState } from "vuex";
import toast from "@/mixins/toast";

export default {
  name: "UploadMedia",
  props: {
    // proofID: {
    //   type: String,
    //   default: () => (null),
    // },
  },
  mixins: [toast],
  data() {
    return {
      //images
      itemExists: false,
      inData: null,
      hasImages: false,
      hasQueue: false,
      imageFiles: [],
      imagesData: [],
      imagesNames: [],
      imageShow: false,
      maxImages: 10,
      maxImagesAllowed: 10,
      imageCount: 0,
      uploadedImagesCount: 0,
      images: {
        visible: true,
        message: "",
        result: false,
        status: "",
      },
      //end images
      isLoading: false,
    };
  },
  methods: {
    showFile(index) {
      return URL.createObjectURL(index);
    },
    processImageUpload() {
      console.log("uploading images!");
      console.table(this.imagesData);
      this.isLoading = true;
      let formData = new FormData();
      for (var i = 0; i < this.imagesData.length; i++) {
        let file = this.imagesData[i];
        formData.append("files[" + i + "]", file);
      }
      formData.append("token", this.$route.query.token);
      formData.append("action", "uploadImages");
      //begin post --------------------------------------------
      this.axios({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "post",
        url: `${this.$env.api}/wasabi/uploadimages_aws.php`,
        data: formData,
      })
        .then((response) => {
          this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.imagesNames = [];
            for (let i in response.data.list) {
              this.imagesNames.push(response.data.list[i]);
              this.imageFiles.push({
                id: this.imageFiles.length + 1,
                image: response.data.list[i],
                pos: this.imageFiles.length + 1,
              });
            }
            // this.toast(response.data.message, "is-success");
            // show just uploaded
            this.imagesData = [];            
            this.imagesCount();
            console.log(this.imageFiles);
            console.log(this.imagesNames);
          } else {
            this.images.result = true;
           // this.images.message = response.data.message;
            this.images.status = "is-danger";
            this.toast(response.data.message, "is-danger");
            //console.log(response.data);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.toast(error, "is-success");
        })
        .finally(() => {
          this.updateDB();
        });
    },
    removeImageFile(image) {
      this.imagesData.splice(image, 1);
      this.imagesCount();
    },
    // delete methods --------------------------------------------------
    deleteImage(image) {
      console.log("deleteing: ",image);
      this.isLoading = true;
      //begin post --------------------------------------------
      this.axios
        .post(`${this.$env.api}/wasabi/manage_images_aws.php`, {
          token: this.$route.query.token,
          filename: image,
          action: "deleteMultiple",
        })
        .then((response) => {
          this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.toast(response.data.message, "is-success");
            this.updateDB();
          } else {
            this.images.result = true;
            this.images.message = response.data.message;
            this.images.status = "is-danger";
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.images.result = true;
          this.images.message =
            "Sorry, something went wrong, please try again.";
          console.log(error);
          this.toast(error, "is-danger");
        });
    },
    //---------------------------------------
    //GALL IMAGES ---------------------------
    warningAdvertFile(image, index) {
      this.$buefy.dialog.confirm({
        title: "Deleting Image",
        message:
          "Are you sure you want to <b>delete</b> this image? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteAdvertFile(image, index),
      });
    },
    deleteAdvertFile(image, index) {
      console.log(image);
      console.table(this.imageFiles);
      //this.hasImagesFiles = this.hasImagesFiles.filter(item => item !== image);
      this.deleteImage(image);
      this.imageFiles.splice(index, 1);
      console.table(this.imageFiles);
      if (this.imageFiles.length >= 1) {
        this.hasImages = true;
      }
      this.imagesCount();
    },

    //-----------------------------------------------------------------
    //save data
    uploadImages() {
      if (this.imagesData) {
        this.processImageUpload();
      } else {
        this.images.result = true;
        this.images.status = "is-info";
        this.images.message =
          "Sorry, something went wrong, try to add some new images.";
        console.log("Sorry, something went wrong, try to add some new images.");
      }
    },

    updateDB(emit = true) {
      console.log("saving images!");
      this.isLoading = true;
      let images = {
        images: this.imageFiles,
      };
      //begin post --------------------------------------------
      this.axios
        .post(`${this.$env.api}/adverts/manage_adverts.php`, {
          action: "updateImages",
          token: this.$route.query.token,
          itemID: this.isEditingID,
          images: JSON.stringify(images),
        })
        .then((response) => {
          this.isLoading = false;
          this.images.result = true;
          this.images.message = response.data.message;
          console.log(response.data);
          if (response.data.success) {
            this.toast(response.data.message, "is-success");
            if(emit){
              this.$emit("update");
            console.log("did it emit");
            }
          } else {
            this.toast(response.data.message, "is-danger");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.toast(error, "is-danger");
        });
      //end post-------------------------------------------------
    },
    //-----------------------------------------------------------
    imagesCount() {
      let currentImageNum = 0;
      if (this.imageFiles) {
        currentImageNum = this.imageFiles.length;
      }
      this.imageCount = currentImageNum + this.imagesData.length;
      this.maxImages = this.maxImagesAllowed - this.imageFiles.length;
      // this.maxImages = 6 - this.imageCount;
      // console.log("total images count:" + this.imageCount);
      if (this.imageCount > this.maxImagesAllowed) {
        this.imagesData = this.imagesData.slice(0, this.maxImages);
        this.images.message = "Only "+this.maxImagesAllowed+" images are allowed!";
        this.images.result = true;
        this.images.status = "is-danger";
      } else {
        this.images.message = "";
        this.images.result = false;
        this.images.status = "";
      }
      if(this.imagesData.length>0){
        this.hasQueue = true;
      }else{
        this.hasQueue = false;
      }
      if(currentImageNum>0){
        this.hasImages = true;
      }else{
        this.hasImages = false;
      } 
    },

    setImageData(inImagesData) {
      console.log("setting image data..");
      if (!inImagesData.images) {
        this.hasImages = false;
        toast("No images found", "is-danger");
        return;
      }
      console.log(inImagesData.images.images);
      this.imageFiles = structuredClone(inImagesData.images.images);
      this.imagesCount();
      this.hasImages = true;
    },

    loadImagesData() {
      console.log('trying to get images data..', this.isEditingID);
      this.isLoading = true;
      this.imagesData = [];
      this.axios
        .post(`${this.$env.api}/adverts/manage_adverts.php`, {
          token: this.$route.query.token,
          itemID: this.isEditingID,
          action: "getItemImages",
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            this.setImageData(response.data.item);
            this.itemExists = true;
          } else {
            console.log("Error getting data");
            this.itemExists = false;
          }
        })
        .catch((error) => {
          this.hasImages = false;
          this.itemExists = false;
          console.log(error);
          //this.errored = true;
        })
      .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
   this.loadImagesData();
  },
  computed: {
    ...mapState({
      user: (state) => state.users.user,
      lang: (state) => state.lang,
      isEditingID: (state) => state.adverts.advert.isEditingID,
    }),
  },
  watch: {
    isEditingID: function (newVal) {
      console.log(newVal);
      //this.loadImagesData();
    },
  },
};
</script>
<style scoped>
.image {
  /* min-width: 250px; */
  float: right;
  max-height: 200px;
}
.image-tag {
  position: absolute;
  top: 20px;
  right: 5px;
  z-index: 1;
}
.imageitem {
  position: relative;
  padding: 10px;
  margin: auto;
}
.queue-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  border:#a1a1a1 dashed 1px;
  padding: 10px;
  border-radius: 10px;
}
@media all and (min-width: 1200px) {
  .queue-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media all and (min-width: 960px) and (max-width: 1199px) {
  .queue-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media all and (min-width: 768px) and (max-width: 959px) {
  .queue-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media all and (min-width: 480px) and (max-width: 767px) {
  .queue-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media all and (max-width: 599px) {
  .queue-grid {
    grid-template-columns: 1fr;
  }
}
@media all and (max-width: 479px) {
  .queue-grid {
    grid-template-columns: 1fr;
  }
}

/*--------------------------------------------*/

.uploader-message-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 100%;
}
.uploader-message{
  text-align: center;

}
.uploader-message .icon{
  font-size: 40px;
  color: #a1a1a1;
}

</style>
<style>
.upload-draggable {
  width: 100%;
}
.upload {
  width: 100%;
}
</style>
