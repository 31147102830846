import Vue from "vue";
import Vuex from "vuex";


import users from './modules/users';
import adverts from './modules/adverts';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: "en",
  },
  getters: {},
  mutations: {
    setLang(state, lang) {
      console.log("setLanguage:", lang);
      state.lang = lang;
      localStorage.setItem("sxLang", lang);
    }
  },
  actions: {},
  modules: {
    users,
    adverts,
  },
});
