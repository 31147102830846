<template>
  <div id="app">
    <div class="main">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  name: "app",
  components: {},
  data() {
    return{};
  },
  // computed: {
  //   ...mapGetters("users", ["user"]),
  // },
  mounted() {
    console.log("APP MOUNTED!");
    //this.$route.query.token ? this.$store.dispatch("verifyToken", this.$route.query.token) : null;
    console.log("token",this.$route.query.token);
    console.log("id",this.$route.query.id);
    //commit editing id
    this.$store.commit("adverts/setEditing", this.$route.query.id);
    this.validateToken();
    //console.log("GenKey",this.generateSecretKey());
    //http://localhost:8080/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOm51bGwsImF1ZCI6bnVsbCwiaWF0IjpudWxsLCJuYmYiOm51bGwsImRhdGEiOnsiaWQiOiIxIiwibmFtZSI6bnVsbCwiZW1haWwiOiJlZ29vbW5pQGdtYWlsLmNvbSIsInN0YXR1cyI6bnVsbCwidHlwZSI6ImlzVXNlciJ9fQ.gVLTmxZicz6NG2J4AslIjoQtM1LcY8W1zj2QewE1Fok
//http://localhost:8080/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOm51bGwsImF1ZCI6bnVsbCwiaWF0IjpudWxsLCJuYmYiOm51bGwsImRhdGEiOnsiaWQiOiIyIiwibmFtZSI6ImZveCBzb2x1dGlvbnMiLCJlbWFpbCI6ImZveHNvbHV0aW9uc3JsQGdtYWlsLmNvbSIsInN0YXR1cyI6bnVsbCwidHlwZSI6ImlzVXNlciJ9fQ.B-pFnLRyJWzq6LpkMNIHgQtbch6Y7GmkwQn16I4VzkI&id=1
  },
  methods: {
    validateToken: function () {
      if (this.$route.query.token) {
        console.log("Validating Token..");
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/users/validate.php`, {
            token: this.$route.query.token,
          })
          .then((response) => {
            console.log("RESPONCE:" + JSON.stringify(response));
            if (response.data.success) {
              this.$store.commit("users/validateUser", response.data.data);
              console.log("Loged!");
              console.log(response.data.data);
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("No Token!");
      }
    },

  },
  beforeDestroy() {
    this.$store.commit("users/logoutUser");
  },
  created() {
    //this.countInbox();
   // this.pollData();
  },
};
</script>

<style lang="scss">

#app{
  background-color: #f5f5f5;
}

//Import Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap");

// Import Fontawesome
//@import "static/fontawesome/css/all.min.css";
@import "static/syntho/syntho-style.css";
@import "static/salex/style.css";
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
// Set your colors
$primary: #97a3ae;
$success: #7cbc4b;
// $modal-background-background-color: #000;
$primary-invert: findColorInvert($primary);
//theme mod
$mydark: #23262b; //#00bb8f; //00D1B2
$mylight: #ebebeb; //#00bb8f; //00D1B2
// $dark: #0d4553;
$filter: #f4f4f4; //#00bb8f; //00D1B2
$sidebar-box-shadow: 0px;
$sidebar-width: 330px;
// $body-background-color: #4a4f54;
$content-heading-color: #000000;

$sxportable: #ffcd0b;
$sxmodular: #f37422;
$sxcustom: #ed3a25;
$sxgraphic: #71cee3;
$sxproject: #b393b0;

$sx-violet: #6E4FD7;
$sx-pink: #FF008D;
$sx-orange: #FFB000;
$sx-bluegreen: #00C793;

$sxgreen: #7cbc4b;
$sxgreen-light: #8dc457;
$sxgreen-dark: #669f42;
$sxgreen-dark2: #5f9340;

$sxgray: #4a4f54;
$sxgray2: #41464c;
$sxgray3: #383c41;
$sxgray4: #2e3136;
$sxgray5: #23262b;

$sxlight: #97a3ae;
$sxlight2: #d8dfe1;
$sxlight3: #f7f7f7;

// $navbar-item-color: $sxgray!important;
// $navbar-dropdown-arrow: $sxgray!important;

$footer-background-color: $sxgray5;
$footer-color: $sxlight2;
$footer-padding: 2rem 1.5rem 2rem;

//$family-primary: 'Roboto', sans-serif;
$family-primary: "Roboto", sans-serif;

$card-radius: 0.5rem;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
  "mydark": (
    $mydark,
    $white,
  ),
  "mylight": (
    $mylight,
    $black,
  ),
  "filter": (
    $filter,
    $white,
  ),
  "sxportable": (
    $sxportable,
    $white,
  ),
  "sxmodular": (
    $sxmodular,
    $white,
  ),
  "sxcustom": (
    $sxcustom,
    $white,
  ),
  "sxgraphic": (
    $sxgraphic,
    $white,
  ),
  "sxproject": (
    $sxproject,
    $white,
  ),
  "sxgreen": (
    $sxgreen,
    $white,
  ),
  "sxgreen-light": (
    $sxgreen-light,
    $white,
  ),
  "sxgreen-dark": (
    $sxgreen-dark,
    $white,
  ),
  "sxgreen-dark2": (
    $sxgreen-dark2,
    $white,
  ),
  "sxgray": (
    $sxgray,
    $white,
  ),
  "sxgray2": (
    $sxgray2,
    $white,
  ),
  "sxgray3": (
    $sxgray3,
    $white,
  ),
  "sxgray4": (
    $sxgray4,
    $white,
  ),
  "sxgray5": (
    $sxgray5,
    $white,
  ),
  "sxlight": (
    $sxlight,
    $white,
  ),
  "sxlight2": (
    $sxlight2,
    $sxgray,
  ),
  "sxpink": (
    $sx-pink,
    $white,
  ),
  "sxviolet": (
    $sx-violet,
    $white,
  ),
  "sxorange": (
    $sx-orange,
    $black,
  ),
  "sxbluegreen": (
    $sx-bluegreen,
    $white,
  ),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$input-icon-color: $sxlight;
$input-icon-active-color: $sxgray;







.modal-card-title {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.main {
  padding-top: 40px;
  min-height: 100vh;
}


.link-bt {
  cursor: pointer;
}

.link-red {
  color: #ed3a25;
}

.link-green {
  color: #7cbc4b;
}


.ico-bt {
  all: unset;
  cursor: pointer;
  font-size: 20px;
}

.ico-red {
  color: #f14668;
}
.ico-green {
  color: #7cbc4b;
}
.ico-blue {
  color: #3e8ed0;
}
.ico-orange {
  color: #ffa600;
}
.ico-gray {
  color: #4a4f54;
}

.red-bt,
.green-bt,
.oranj-bt,
.blue-bt {
  color: #c9c9c9;
  cursor: pointer;
  float: left;
}

.red-bt:hover {
  color: #f14668;
}
.green-bt:hover {
  color: #7cbc4b;
}
.blue-bt:hover {
  color: #3e8ed0;
}
.oranj-bt:hover {
  color: #ffa600;
}

.green-bt-active {
  color: #7cbc4b;
}

.table {
  background-color: transparent !important;
}

.icon {
  //margin: 5px 5px 0px 0px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

// .grid:before {
//   content: "" !important;
// }

// .image:before {
//   content: "" !important;
// }

// .check:before {
//   content: "" !important;
// }

.not-clickable {
  pointer-events: none;
}

.not-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.menu-list a {
  font-family: "Roboto", sans-serif;
  // text-transform: uppercase;
  font-size: .9em !important;
}

//fixes
.tag::before{
  content: none !important;
}

.label{
 text-transform: capitalize !important; 
}

.sx-block-title{
  color: hsl(0deg, 0%, 21%);
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

//user account styles
.sx-section-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e6e6e6;
}

.sx-horizonal-line {
    border-right: 1px solid #e6e6e6;
}

.dashboard-box {
  color: #7a7a7a;
  cursor: pointer;
}


//user dashboard styles
.dashboard-box:hover .el-color {
  color: #fff;
}

.box-bt {
  text-align: center;
  color: #4a4f54 !important;
  font-size: 1.5rem;
  font-weight: 300;
  cursor: pointer;
}

.box-bt:hover {
  color: #ffffff !important;
  background-color: #7cbc4b;
}

.box-green .el-color {
  color: #7cbc4b;
}

.box-green:hover {
  background-color: #7cbc4b;
  color: #fff;
}

.box-red .el-color {
  color: #f14668;
}

.box-red:hover {
  background-color: #f14668;
  color: #fff;
}

.box-orange .el-color {
  color: #ffa600;
}

.box-orange:hover {
  background-color: #ffa600;
  color: #fff;
}

.box-blue .el-color {
  color: #3e8ed0;
}

.box-blue:hover {
  background-color: #3e8ed0;
  color: #fff;
}

.box-gray .el-color {
  color: #4a4f54;
}

.box-gray:hover {
  background-color: #4a4f54;
  color: #fff;
}

.box-text{
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}
// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
// @import "~bulma-prefers-dark/bulma-prefers-dark.sass"; ! this is a potential solutionn for dark mode
//Uses @media (prefers-color-scheme: dark) to detect dark mode
</style>
