export default{
  namespaced: true,
  state: {
    advert: {
      isEditingID: 1,
    },
  },
  getters: {
    isEditingAdvert: (state) => {
      return state.advert.isEditingID;
    }
  },
  mutations: {
    setEditing(state, payload) {
      localStorage.removeItem("sxEditingAdvert");
      localStorage.sxEditingAdvert = payload;
      state.advert.isEditingID = payload;
    },
    unsetEditing(state){
      localStorage.removeItem("sxEditingAdvert");
      state.advert.isEditingID = null;
    },
  },
  actions: {
  },
  modules: {
  }
}
