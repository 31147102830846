<template>
  <div class="home container">
    <img class="logo" alt="Salex Logo" src="../assets/sx_logo.svg" />
    <div class="my-3 has-text-centered is-size-3">Image Uploader</div>
    <UploadMedia v-if="user.isLogged" />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import UploadMedia from "@/components/UploadMedia.vue";

export default {
  name: "HomeView",
  components: {
    UploadMedia,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("users", ["user"]),
  },
};
</script>

<style scoped>
.logo{
  width: 200px;
  margin: 0 auto;
  display: block;
}
</style>
