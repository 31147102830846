export default{
  namespaced: true,
  state: {
    user: {
      isLogged: false,
      name: null,
      email: null,
      status: null,
      id: null,
    },
  },
  getters: {
    isUserEmail: (state) => {
      return state.user.email;
    }
  },
  mutations: {
    logoutUser(state) {
      state.user.isLogged = false;
      state.user.name = null;
      state.user.email = null;
      state.user.status = null;
      state.user.id = null;

    },
    validateUser(state, payload) {
      state.user.isLogged = true;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.status = payload.status;
      state.user.id = payload.id;
    },
    clearUserData(state) {
      state.user.isLogged = false;
      state.user.name = null;
      state.user.email = null;
      state.user.status = null;
      state.user.id = null;
    },
  },
  actions: {
    loginUser({commit,dispatch}, payload) {
      commit("loginUser", payload);
    },
    logoutUser({commit}) {
      commit("logoutUser");
    },
  },
  modules: {
  }
}
